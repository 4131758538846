import React from "react";
import { FaCamera } from "react-icons/fa";
import { FaCarAlt } from "react-icons/fa";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { IoGameController } from "react-icons/io5";
import { LuMonitor } from "react-icons/lu";
import { MdOutlineTabletMac } from "react-icons/md";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FaWifi } from "react-icons/fa";
import { FaVirusCovid } from "react-icons/fa6";
import { MdOutlineBrowserUpdated } from "react-icons/md";

function LeftSection() {
  return (
    <Box w={{base:"100%",lg:"70%"}} p={"15px"} bg={"#f8f9fa"}>
      <Box bg={"#343a40"} p={"10px 30px 10px 30px"}>
        <Text
          textAlign={"center"}
          color={"white"}
          fontWeight={500}
          fontSize={"28px"}
          lineHeight={"34px"}
        >
          Choose Services from Categories Below
        </Text>
      </Box>
      <Flex p={"20px"} flexWrap={"wrap"}>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <FaCamera style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Cameras
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <FaCarAlt style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Car Electronics
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <MdOutlinePhoneAndroid style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Cell Phones
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <IoGameController style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Video Gaming
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <LuMonitor style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              TV & Home Theater
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <MdOutlineTabletMac style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Computers & Tablets
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <FaWifi style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Wifi printer set up & Driver update
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <FaVirusCovid style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Virus Protection
            </Text>
          </Box>
        </Box>
        <Box
          w={{base:"50%",lg:"33.3%"}}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={"30px"}
          gap={"15px"}
        >
          <Box>
            <MdOutlineBrowserUpdated style={{ fontSize: "50px" }} />
          </Box>
          <Box>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"29px"}
              color={"#dc3545"}
              textAlign={"center"}
            >
              Computer & Update
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default LeftSection;
