import { Box, Grid, Image, Text } from '@chakra-ui/react'
import React from 'react';
import kitchen from "../assets/kitchen.jpg";
import electricCar from "../assets/electricCar.jpg"
import cellPhone from "../assets/cellPhone.jpg"
import tv from "../assets/tv.jpg";
import ipod from "../assets/ipod.jpg";
import games from "../assets/games.jpg";
import computer from "../assets/computer.jpg";
import camera from "../assets/camera.jpg";
import homeDevice from "../assets/homeDevice.jpg"

function ServicesServe() {

    const service = [
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328940/kitchen.0448b4b538f9362ca423_yvr51u.jpg",
            title:"Kitchen Devices",
            description:"Microwave, Dishwasher, Microwave, Purifier, Dryer, Heater, Oven, Cookers, Coffee Machines, Cookware and Bake-ware, Small Kitchen Appliances",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328939/electricCar.80cb18fe0f930e433c7d_ac3jxq.jpg",
            title:"Car Electric Products",
            description:"Automatic Car Seats, Remote Controls car systems",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328938/cellPhone.a8d1aa4a389ae876dde3_yxwmja.jpg",
            title:"Cell phones and mobiles",
            description:"Cell phones, phone accessories, phone Bluetooth, phone speakers, phone cameras",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328940/computer.f249d2bc2493b5307ff6_fbmdgj.jpg",
            title:"Computer and Printers",
            description:"Computers, Tablets, Desktops, Laptops, Monitors, Printers, Scanners, Networking Device, Storage Device, Software Devices, eReaders",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328939/camera.7463698d01272fbc27e0_be4tla.jpg",
            title:"Cameras and DSLRs",
            description:"Mirrorless Cameras, DSLR Cameras, Zoom Cameras, Tough Cameras, Camera Accessories",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328983/homeDevice.a60f72e49ac4f11a3cd5_ddiyfb.jpg",
            title:"Home Devices",
            description:"Smart clocks, locks, bells, lights, Bluetooth devices, networking devices, etc..",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328940/tv.d1b0c4cbaccd8faf88be_dnyeb0.jpg",
            title:"TV and Home Theatre",
            description:"TV Installation and Setup, Smart TVs, QLED, OLED, Mounting TV, TV accessories, Home cinema, projectors, graphic cards, memory",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328939/games.45bd1414ccb8c6b7ee51_pmqw7m.jpg",
            title:"Video Games",
            description:"Video games, remote controls, gaming consoles",
        },
        {
            image:"https://res.cloudinary.com/dmzzzl5jj/image/upload/v1712328942/ipod.78f61e16d867aba313d1_rvqnik.jpg",
            title:"Portable Audio",
            description:"Headphones, speakers, soundbars, WIFI systems, players, audiovisual devices",
        }
    ]
  return (
    <div>
        <Box>
            <Grid templateColumns={[
                "repeat(1, 1fr)",
                "repeat(1, 1fr)",
                "repeat(1, 1fr)",
                "repeat(3, 1fr)",
              ]}
              direction={{ base: "column", md: "row" }}
              gap="20px">
                {service.map((e,i) => (
                    <>
                        <Box key={i} border={"1px solid lightgrey"} w={"100%"}>
                            <Box w={"100%"} h={"190px"}>
                                <Image objectFit={"cover"} w={"100%"} h={"100%"} src={e.image} />
                            </Box>
                            <Box mt={"0px"} display={"flex"} flexDirection={"column"} gap={"10px"} p={"20px"}>
                                <Box>
                                    <Text textAlign={"center"} fontWeight={700} fontSize={"24px"} lineHeight={"29px"}>{e.title}</Text>
                                </Box>
                                <Box>
                                    <Text textAlign={"justify"} fontWeight={400} fontSize={"17px"} lineHeight={"24px"}>{e.description}</Text>
                                </Box>
                            </Box>
                        </Box>
                    </>
                ))}
            </Grid>
        </Box>
    </div>
  )
}

export default ServicesServe