import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

function ServicesCare() {
  return (
    <div>
        <Flex bg={"#f8f9fa"} textAlign={"center"} gap={"40px"} p={"20px"} direction={{base:"column",lg:"row"}}>
            <Box display={"flex"} flexDirection={"column"} gap={"10px"} w={{base:"100%",lg:"33.3%"}}>
                <Text fontWeight={600} fontSize={"24px"} lineHeight={"29px"}>Geeks Services</Text>
                <Text fontWeight={400} fontSize={"18px"} lineHeight={"24px"}>Geeks services provides the best experts for assistance for your computer, laptop and other smart home devices.</Text>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"10px"} w={{base:"100%",lg:"33.3%"}}>
                <Text fontWeight={600} fontSize={"24px"} lineHeight={"29px"}>Strength In Numbers</Text>
                <Text fontWeight={400} fontSize={"18px"} lineHeight={"24px"}>On call agents are available across the country providing the best in class remote support services..</Text>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"10px"} w={{base:"100%",lg:"33.3%"}}>
                <Text fontWeight={600} fontSize={"24px"} lineHeight={"29px"}>Expert Repair</Text>
                <Text fontWeight={400} fontSize={"18px"} lineHeight={"24px"}>No matter where you bought your cell phone or computer, geeks can fix them.</Text>
                <Text fontWeight={400} fontSize={"18px"} lineHeight={"24px"}>You can have large TVs and appliances repaired online if you have a geeks care plan or warranty.</Text>
            </Box>
        </Flex>
    </div>
  )
}

export default ServicesCare