import { Box, Text } from '@chakra-ui/react'
import React from 'react'

function Contents() {
  return (
    <div>
        <Box w={{base:"90%",lg:"850px",xl:"1140px"}} m={"auto"} pb={{lg:"30px"}} pt={{base:"30px",lg:"40px"}} color={"#212529"}>
            <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                <Box>
                    <Text fontWeight={400} fontSize={"16px"} lineHeight={"24px"} letterSpacing={"1px"}>
                    Geeks services revolve around the overall servicing of the products. Experts are available to provide you with unlimited answers to your device problems. Geeks plans provide the best help for your device issues. it provides maintenance, protection, repair and replacement, and overall solutions for all customers nationwide through our official website Geeks. You may apply even remotely for the plans from your home via the website. In case of any questions or problems, you can get in touch with the 24-hour customer service number.
                    </Text>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                    <Box>
                        <Text fontWeight={500} fontSize={"32px"} lineHeight={"38px"} letterSpacing={"1px"}>Geeks Services: Why Choose Us?</Text>
                    </Box>
                    <Box>
                        <Text fontWeight={400} fontSize={"16px"} lineHeight={"24px"} letterSpacing={"1px"}>The Geeks services are available anytime and anywhere for users and agents alike. You can reach out to Geeks anytime and anywhere and schedule an appointment. There are several reasons why people choose the Geeks services, including: They are provided by industry-trained staff members who have years of experience.</Text>
                    </Box>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"} letterSpacing={"1px"}>
                    <Box>
                        <Text fontWeight={500} fontSize={"32px"} lineHeight={"38px"}>Geeks Services for Products and Devices</Text>
                    </Box>
                    <Box>
                        <Text fontWeight={400} fontSize={"16px"} lineHeight={"24px"}>Geeks provides professional assistance and expert solutions for various services and devices. Such As-</Text>
                    </Box>
                    <Box p={"15px 20px 15px 40px"}>
                        <ul>
                            <li>Electronics Devices</li>
                            <li>Computer And Mobile Devices</li>
                            <li>Recording cameras, video cameras, and camcorders</li>
                            <li>Home Theater and Tv Appliance</li>
                            <li>PC And Handheld Gaming</li>
                            <li>Car Electronics & GPS Installation Accessories</li>
                        </ul>
                    </Box>
                </Box>
            </Box>
        </Box>
    </div>
  )
}

export default Contents