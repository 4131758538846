import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import LeftSection from '../Subcomponents/LeftSection'
import RightSection from '../Subcomponents/RightSection'

function HeroSection({HomeSec,ContactSec,scrollToSection}) {
  return (
    <div ref={HomeSec}>
        <Box backgroundImage="url('')" position={"relative"} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} w={"100%"} minH={"450px"} display={"flex"} alignItems={"center"}>
            <Box w={{lg:"850px",xl:"1140px"}} m={"auto"} >
                <Flex gap={"20px"} direction={{base:"column",lg:"row"}}>
                    <LeftSection/>
                    <RightSection ContactSec={ContactSec} scrollToSection={scrollToSection}/>
                </Flex>
            </Box>
        </Box>
    </div>
  )
}

export default HeroSection