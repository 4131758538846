import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import Carousel from "react-elastic-carousel";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosSearch,
  IoLogoGoogleplus,
} from "react-icons/io";

function Testimonials() {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 320, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 960, itemsToShow: 2 },
    {width:1200 , itemsToShow:2}
  ];

  const carouselArrowStyles = {
    position: "absolute",
    top: "50%",
    width: "30px",
    height: "20px",
    fontSize: "33px",
    fontWeight: "700",
    zIndex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    color: "grey",
    cursor: "pointer",
    userSelect: "none",
    transition: "all 0.2s ease-in-out",
    _hover: {
      backgroundColor: "blue",
      color: "red",
    },
    display: "flex",
    visibility: "visible",
  };

  const testimonials = [
    {
      description:"The great thing about geek is that there is no deductible for replacing or repairing my electronics.",
      owner:"— Marry G., WA"
    },
    {
      description:"Geeks is the main reason I purchase tech products online. Every time I've had to utilize my GeekcareProtection Plans, it has been a positive experience..",
      owner:"— Scott R., TX"
    },
    {
      description:"Geek's zero deductibles redefine tech shopping. Hassle-free repairs and replacements make them my go-to choice. Unbeatable service and satisfaction!",
      owner:"— Pierre Laurent"
    },
    {
      description:"Geek is my tech haven. No deductibles mean stress-free purchases. Their reliable service and positive experiences make them my trusted companion.",
      owner:"— Elena Rodriguez"
    },
    {
      description:"Geek's commitment shines through zero deductibles. Stress-free repairs and replacements make every encounter positive. Geek is my tech guardian, always delivering satisfaction.",
      owner:"— Hans Schmidt"
    }
  ]
  return (
    <div>
      <Box bg={"#343a40"} color={"white"} p={"20px"}>
        <Text textAlign={"center"} fontWeight={500} fontSize={"32px"} lineHeight={"38px"}>What Our Clients Have to Say</Text>
      </Box>
      <Flex mt={"20px"} position="relative" >
        
        <Carousel itemsToShow={2} itemPadding={[0, 10]} breakPoints={breakPoints} pagination={false}   renderArrow={({ type, onClick }) => (
          <div
            onClick={onClick}
            style={{
              ...carouselArrowStyles,
              left: type === "PREV" ? "10px" : "auto",
              right: type === "NEXT" ? "10px" : "auto",
            }}
          >
            {type === "PREV" ? <IoIosArrowBack /> : <IoIosArrowForward />}
          </div>
        )}>
          {testimonials.map((e,i) => (
            <>
            <Box key={i} m={{base:"auto",lg:"0"}} w={{base:"100%",lg:"100%"}} bg={"#f8f9fa"} p={"20px"} display={"flex"} flexDirection={"column"} gap={"10px"}>
              <Box>
                <Text fontWeight={400} fontSize={"16px"} lineHeight={"24px"}>
                  {e.description}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={600} fontSize={"16px"} lineHeight={"24px"}>{e.owner}</Text>
              </Box>
            </Box>
            </>
          ))}
        </Carousel>
      </Flex>
    </div>
  );
}

export default Testimonials;
