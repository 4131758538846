import { Box, Text } from '@chakra-ui/react'
import React from 'react'

function Footer() {
  return (
    <div>
        <Box w={"100%"} bg={"#131a2a"} color={"white"} p={"15px"}>
            <Text textAlign={"center"}>Copyright Geek All Rights Reserved</Text>
            <Box letterSpacing={"1px"} pt={"40px"} pb={"40px"} borderTop={"1px solid white"} w={{base:"90%",lg:"850px",xl:"1140px"}} m={"auto"} mt={"20px"}>
              <Text fontSize={"20px"} textAlign={"center"} textDecoration={"underline"}>Privacy Policy</Text>
              <Text textAlign={"center"}>It is Geek Tech's policy to respect your privacy regarding any information we may collect while operating our website. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, usmunicate, disclose and otherwise make use of personal information. We have outlined our privacy policy below.</Text>
            </Box>
        </Box>
    </div>
  )
}

export default Footer