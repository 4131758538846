import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainHome from '../Pages/Home'
import SuceedPage from '../Pages/SuceedPage'

function AllRoutes({scrollToSection,HomeSec,ServicesSec,ContactSec}) {
  return (
    <>
        <Routes>
            <Route path='/' element={<MainHome scrollToSection={scrollToSection} HomeSec={HomeSec} ServicesSec={ServicesSec} ContactSec={ContactSec} />} />
            <Route path='/success' element={<SuceedPage/>} />
        </Routes>
    </>
  )
}

export default AllRoutes