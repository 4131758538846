import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import RightSection from '../Subcomponents/RightSection'
import MessageBox from '../Subcomponents/MessageBox'

function Contact({ContactSec}) {
  return (
    <div ref={ContactSec}>
        <Box bg={"#f8f9fa"} mt={"150px"} pt={"40px"} pb={"100px"}>
            <Box w={{base:"90%",lg:"850px",xl:"1140px"}} m={"auto"}>
                <Box bg={"#343a40"} color={"white"} p={"20px"}>
                  <Text textAlign={"center"} fontWeight={500} fontSize={"32px"} lineHeight={"38px"}>Book Your Appointment</Text>
                </Box>
                <Flex mt={"20px"} gap={"20px"} direction={{base:"column",lg:"row"}}>
                    <MessageBox/>
                    <RightSection/>
                </Flex>
            </Box>
        </Box>
    </div>
  )
}

export default Contact