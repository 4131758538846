import React from 'react'
import HeroSection from '../Components/HeroSection'
import { Box } from '@chakra-ui/react'
import Contents from '../Components/Contents'
import Services from '../Components/Services'
import Contact from '../Components/Contact'

function Home({scrollToSection,HomeSec,ServicesSec,ContactSec}) {
  return (
    <Box mt={{base:"80px",md:"100px",lg:"130px",xl:"120px"}} letterSpacing={"1px"}>
        <HeroSection HomeSec={HomeSec} ContactSec={ContactSec} scrollToSection={scrollToSection} />
        <Contents/>
        <Services ServicesSec={ServicesSec}  />
        <Contact ContactSec={ContactSec} />
    </Box>
  )
}

export default Home