import { useRef } from "react";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import AllRoutes from "./Routes/AllRoutes";

function App() {

  const HomeSec = useRef(null)
  const ServicesSec = useRef(null)
  const ContactSec = useRef(null)


  const scrollToSection = (elementRef) => {
    console.log(elementRef)
    if (elementRef && elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: 'smooth',
      });
    } else {
      // Handle the case where the ref is not available
      console.error('Ref is not available');
      // Optionally, you could scroll to the top of the page or do something else
      // Example: window.scrollTo(0, 0);
    }
  };


  return (
    <div>
      <Navbar scrollToSection={scrollToSection} HomeSec={HomeSec} ServicesSec={ServicesSec} ContactSec={ContactSec} />
      <AllRoutes scrollToSection={scrollToSection} HomeSec={HomeSec} ServicesSec={ServicesSec} ContactSec={ContactSec} />
      <Footer/>
    </div>
  );
}

export default App;
