import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import LeftSection from "../Subcomponents/LeftSection";
import RightSection from "../Subcomponents/RightSection";
import ServicesServe from "../Subcomponents/ServicesServe";
import ServicesCare from "../Subcomponents/ServicesCare";
import Testimonials from "../Subcomponents/Testimonials";

function Services({ServicesSec}) {
  return (
    <div ref={ServicesSec}>
      <Box w={{ base: "90%", lg: "850px", xl: "1140px" }} m={"auto"}>
        {/*
        <Flex gap={"20px"} direction={{base:"column",lg:"row"}}>
                <LeftSection/>
                <RightSection/>
            </Flex>
        */}
        <Box mt={"20px"}>
          <ServicesServe />
        </Box>
        <Box mt={"20px"}>
          <ServicesCare />
        </Box>
        <Box mt={"20px"}>
          <Testimonials />
        </Box>
      </Box>
    </div>
  );
}

export default Services;
